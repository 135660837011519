import React, {useState, useEffect} from 'react';
import {Button, Table, Card, Form, Input, Icon, Select, Row} from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import INSERT_BULK_MPS from '../api/mutations/insertBulkMps';
import {Notices} from './Notices';
import CardContainer from './CardContainer';
import LIST_CONDOS from '../api/queries/listCondos';
import { Auth } from 'aws-amplify';

const { Option } = Select;

function AddNewMps(props) {
    const [insertBulk, {loading: mutationLoading, error: mutationError}] = useMutation(INSERT_BULK_MPS);
    const {loading, error, data} = useQuery(LIST_CONDOS);
    useEffect(() => {
      checkUser()
    }, [])
    const [user, setUser] = useState({})
    async function checkUser() {
      try {
        const data = await Auth.currentUserPoolUser()
        const userInfo = { username: data.username, ...data.attributes }
        setUser(userInfo)
      } catch (err) { console.log('error: ', err) }
    }
    const { getFieldDecorator } = props.form;
    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
          const {quarter, year, condominium} = values;
          if (!err) {
                  await insertBulk({
                      variables: {
                          condo_id: condominium,
                          year: year,
                          quarter: quarter,
                          researcher_id: user.username
                      }
                  });
                  props.history.push("/home/update");
          }
        });
      };
    return (
        <>
        {loading || mutationLoading ? 
        <div style={{textAlign: 'center'}}>
  <Card><Icon type="loading" style={{'fontSize': '50px'}}/></Card>
  </div> : 
        <CardContainer>
            <Notices errors={mutationError}/>
      <Form onSubmit={handleSubmit}>
      <Form.Item label="Year">
          {getFieldDecorator('year', {
            rules: [{ required: true, message: 'Please input the year!' }],
          })( <Select style={{ width: 120 }}>
          <Option value={2020}>2020</Option>
          <Option value={2019}>2019</Option>
          <Option value={2018}>2018</Option>
          <Option value={2017}>2017</Option>
          <Option value={2016}>2016</Option>
          <Option value={2015}>2015</Option>

        </Select>)}
        </Form.Item>
        <Form.Item label="Quarter">
          {getFieldDecorator('quarter', {
            rules: [{ required: true, message: 'Please input the Quarter!' }],
          })( <Select style={{ width: 100 }}>
          <Option value="Q1">Q1</Option>
          <Option value="Q2">Q2</Option>
          <Option value="Q3">Q3</Option>
          <Option value="Q4">Q4</Option>

        </Select>)}
        </Form.Item>
      <Form.Item label="Condominium">
      {getFieldDecorator('condominium', {
          rules: [{ required: true, message: 'Please input your Password!' }],
        })(
            <Select>
            {data.developments_schema_condominium.map(d => <Option value={d.id}>{d.name}</Option>)}
            </Select>
        )}
      </Form.Item>
      <Form.Item>
        <Button 
        type="primary" 
        htmlType="submit" 
        style={{float: 'right'}}
        >
          Generate
        </Button>
      </Form.Item>
    </Form>
        </CardContainer>}
        
        </>
    )
}

export default Form.create()(AddNewMps);