/* src/Profile.js */
import React, { useState, useEffect } from 'react';
import { Table, Icon, Row, Col, Card } from 'antd';
import { Auth } from 'aws-amplify';
import { useQuery } from '@apollo/react-hooks';
import USER_HISTORY from './api/queries/UserHistory';

const tabList = [
  {
    key: 'tab1',
    tab: 'tab1',
  },
  {
    key: 'tab2',
    tab: 'tab2',
  },
];

const contentList = {
  tab1: <p>content1</p>,
  tab2: <p>content2</p>,
};

function Profile() {
  useEffect(() => {
    checkUser()
  }, [])
  const [user, setUser] = useState({});
  const { loading, error, data } = useQuery(USER_HISTORY);
  async function checkUser() {
    try {
      const data = await Auth.currentUserPoolUser()
      const userInfo = { username: data.username, ...data.attributes }
      setUser(userInfo)
    } catch (err) { console.log('error: ', err) }
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date_of_action',
      key: 'date_of_action',
      width: '10%'
    },
    {
      title: 'Condominium',
      dataIndex: 'name',
      key: 'name',
      width: '20%'

    },
    {
      title: 'Metric',
      dataIndex: 'variable',
      key: 'variable',
      width: '20%'

    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
      width: '20%'

    },
    {
      title: 'Changes',
      dataIndex: 'diff',
      key: 'diff',
      render: (diff) => {
        return JSON.stringify(diff)
      },
      width: '20%'
    },
  ]
  if (loading) return <div style={{textAlign: 'center'}}>
  <Card><Icon type="loading" style={{fontSize: '50px'}}/></Card>
  </div>;
  if (error) return `Error! ${error.message}`;
  return (
      <>
      <Row gutter={[{ xs: 4, sm: 10, md: 18, lg: 20 }, 20]} style={{ flexWrap: 'wrap'}}>
      <Col sm={24} lg={8} xl={6}>
        <Card borderless style={{textAlign: 'center'}}>
        <Icon type="user" style={{fontSize: '100px'}}/>
        <h2>{user.given_name} {user.family_name}</h2>
        <p>Email: {user.email}</p>
        <p>Phone: {user.phone_number}</p>
        </Card>
      </Col>
      <Col xs={24} lg={16} xl={18}>
      <Card borderless title="Recent Activity">
        <Table columns={columns} dataSource={data.logging_melted_mp_audit}/>
        </Card>
      </Col>
      </Row>
      </>
  );
}

export default Profile;