import gql from 'graphql-tag';


const ALLCONDOS = gql`query AllCondos {
  developments_schema_condominium(order_by: {date_updated: desc}) {
    name
    researcher_analyst
    bldgs
    age_mos
    age_yrs
    amenities
    association_dues_sqm
    bank_financing_bal_amort_mos
    bank_financing_bal_percent
    bank_financing_dp_amort_mos
    bank_financing_dp_percent
    bank_financing_interest_rate_percent
    bldgs
    buyers_profile
    cash_payment_disc_percent
    cbd_location
    city
    comments
    contact_number
    contact_person
    date_of_research
    deferred_payment_mos
    developer
    full_notes
    geo_tagging
    id
    ihf_bal_amort_mos
    ihf_balance_percent
    ihf_dp_amort_mos
    ihf_interest_rate
    ihf_dp_percent
    launch_date
    lot_area_sqm
    miscellaneous_fees
    number_of_floors
    number_of_parking_slots
    pag_ibig_amort
    pag_ibig_dp
    parking_selling_price
    province
    reservation_fee
    selling_point_concept
    status
    street_and_barangay
    turnover_year
  }
}`

export default ALLCONDOS;