import React from 'react';
import {Button, Table} from 'antd';

  function TableMisc (props) {
    const payment_main = [
      {
          title: 'Condominium',
          dataIndex: 'name',
          key: 'name',
      },
      {
          title: 'Building No.',
          dataIndex: 'bldgs',
          key: 'bldgs',
      },
      {
          title: 'Miscellaneous Fees',
          dataIndex: 'miscellaneous_fees',
          key: 'miscellaneous_fees',
      },
      {
          title: 'Association Dues (in PHP/SQM)',
          dataIndex: 'association_dues_sqm',
          key: 'association_dues_sqm',
      },
      {
          title: 'No. of Parking Slots',
          dataIndex: 'number_of_parking_slots',
          key: 'number_of_parking_slots',
      },
      {
        title: 'Parking Selling Price',
        dataIndex: 'parking_selling_price',
        key: 'parking_selling_price',

    },
      {
        title: 'Comments',
        dataIndex: 'comments',
        key: 'comments',
      },
      {
        title: 'Contact Person',
        dataIndex: 'contact_person',
        key: 'contact_person',
      },
      {
        title: 'Contact Number',
        dataIndex: 'contact_number',
        key: 'contact_number',
      },
      {
        title: "Launch Date",
        dataIndex: 'launch_date',
        key: 'launch_date',
      },
      {
        title: "Amenities",
        dataIndex: 'amenities',
        key: 'amenities',
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: () => (
          <span className="table-operation">
            <Button type="secondary" shape="round">Edit</Button>
          </span>
        ),
      },
  ]
 return <Table className="components-table-demo-nested" dataSource={props.dataSource} columns={payment_main} rowKey={(record) => record.id} /> 
  }

  export default TableMisc;