import gql from 'graphql-tag';


const ALL_MELTEDMPS = gql`query allMeltedMps {
  developments_schema_melted_mp {
        condominium {
          name
        }
        condo_id
        data_1br
        data_2br
        data_3br
        data_4br
        data_5br
        is_updated
        id
        loft
        penthouse
        quarter
        researcher_id
        studio
        variable
        year
      }
}`

export default ALL_MELTEDMPS;