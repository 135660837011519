import React, { useState } from 'react';
import { Form, Input, Button, Row, Card, message} from 'antd';
import {Auth} from 'aws-amplify';
import { Notices } from "../components/Notices";


const ForgotPasswordVerification = (props) => {
    const [errors, setErrors] = useState({errors: {
        message: ""
    }});
    const [dirty, setDirty] = useState(false);
    const { getFieldDecorator } = props.form;
      const handleConfirmBlur = e => {
        const { value } = e.target;
        setDirty(true || !!value);
      };
      
    const compareToFirstPassword = (rule, value, callback) => {
        const { form } = props;
        if (value && value !== form.getFieldValue('password')) {
          callback('Two passwords that you enter is inconsistent!');
        } else {
          callback();
        }
      };
      const verificationCodePattern = (rule, value, callback) => {
        const { form } = props;
        const re = new RegExp("^[0-9\\b]{6}$")
        if (!re.test(form.getFieldValue('verification_code'))) {
          callback('Verification codes are all numbers and must be at least 6 numbers.');
        } else {
          callback();
        }
      };
    
    const validateToNextPassword = (rule, value, callback) => {
        const { form } = props;
        if (value && dirty) {
          form.validateFields(['confirm'], { force: true });
        }
        callback();
      };
      const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
          const {verification_code, password} = values;
          if (!err) {
                  await Auth.forgotPasswordSubmit(props.username, verification_code, password).then(message.success(`You've successfully reset your password. You may now log-in again.`)).catch(cognitoerror => {
                  let cognitoerr = null;
                  !cognitoerror.message ? cognitoerr = {"message": cognitoerror} : cognitoerr = cognitoerror;
                  setErrors({...cognitoerr});
                }
                  );
                  props.history();
          }
          else {
            let cognitoerr = null;
            !err.message ? cognitoerr = {"message": err} : cognitoerr = err;
            setErrors({...cognitoerr});
          }
        }
        )};
    return (
        <>
        <Notices errors={errors}/>
        <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}} >
            <Card title="Reset your REAPER Password" style={{width: '375px', height: 'auto'}}>
            <Form onSubmit={handleSubmit}>
            <Form.Item label="Verification Code">
        {getFieldDecorator("verification_code", {
          rules: [
            {
              required: true,
              message: "This is a required field."
            },
            {
              validator: verificationCodePattern
            }
          ],
        })(        <Input
          placeholder="Input a number"
          maxLength={25}
        />)}
        </Form.Item>
            <Form.Item label="New Password" hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                pattern: new RegExp("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$"),
                message: 'Must have at least 8 letters, one special character, and a number',
              },
              {
                validator: validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item label="Confirm New Password" hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={handleConfirmBlur} />)}
        </Form.Item>
    
            <Button 
            type="primary" 
            htmlType="submit" 
            style={{width: '100%'}}
            >
            Submit
            </Button>
            </Form>
            <Button 
        type="link" 
        onClick={props.back}
        style={{margin: '10 px', width: '100%'}}
        >
        Cancel
        </Button>
            </Card>

        </Row>
        </>
    );
};

const WrappedForgotPasswordVerificationForm = Form.create({ name: 'forgot_password_verification' })(ForgotPasswordVerification);


export default WrappedForgotPasswordVerificationForm;