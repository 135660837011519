import React, { useState } from "react";
import { Steps, Divider, Form, Card } from "antd";
import { PaymentForm } from "./PaymentForm";
import { ProfileForm } from "./ProfileForm";
import { MiscForm } from "./MiscForm";
import CardContainer from "./CardContainer";
import SummaryPage from "./SummaryPage";

const { Step } = Steps;

function Demo(props) {
  const [fields, setFields] = useState({

    name: "",
    bldgs: "",
    researcher_analyst: "",
    status: "",
    date_of_research: "",
    city: "",
    developer: "",
    cbd_location: "",
    geo_tagging: "",
    province: "",
    street_and_barangay: "",
    number_of_floors: "",
    lot_area_sqm: "",
    turnover_year: "",
    age_yrs: "",
    age_mos: "",
    first_name: "",
    miscellaneous: "",
    bf_dp: "",
    bank_financing_dp_percent: "",
    bank_financing_dp_amort_mos: "",
    bank_financing_bal_percent: "",
    bank_financing_bal_amort_mos: "",
    bank_financing_interest_rate_percent: "",
    ihf_dp_percent: "",
    ihf_dp_amort_mos: "",
    ihf_bal_percent: "",
    ihf_bal_amort_mos: "",
    ihf_interest_rate: "",
    cash_payment_disc_percent: "",
    deferred_payment_mos: "",
    pag_ibig_dp: "",
    pag_ibig_amort: "",
    reservation_fee: "",
    full_notes: "",
    selling_point_concept: "",
    buyers_profile: "",
    miscellaneous_fees: "",
    association_dues_sqm: "",
    number_of_parking_slots: "",
    parking_selling_price: "",
    comments: "",
    contact_person: "",
    contact_number: "",
    launch_date: "",
    amenities: ""

  });
  const [current, setCurrent] = useState(0);
  const [summary, setSummary] = useState({});
  const steps = [
    {
      title: "Profile",
      description: "Provide details about the condominium."
    },
    {
      title: "Payment Options",
      description: "Indicate the possible payment options."
    },
    {
      title: "Miscellaneous",
      description: "Other details"
    },
    {
      title: "Summary",
    }
  ];

  function prev() {
    const newCurrent = current - 1;
    setCurrent(newCurrent);
  }
  function next() {
    const newCurrent = current + 1;
    setCurrent(newCurrent);
  }
  const handleFormChange = changedFields => {
    setFields({
      ...fields,
      ...changedFields
    });
  };
  const fieldsFlattener = () => {
    var flattened = {};
    Object.keys(fields).map(
      fieldName => (flattened[fieldName] = fields[fieldName].value)
    );
    setSummary({ ...summary, flattened });
    next();
  };


  return (
    <React.Fragment>
    <Card>
      <Steps current={current} style={{backgroundColor: '#FFFFFF'}}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title} description={item.description} />
        ))}
      </Steps>

      </Card>
      <br/>
      {current === 2 && (
        <CardContainer>
          <MiscForm
            {...fields}
            fieldsFlattener={fieldsFlattener}
            prev={prev}
            onChange={handleFormChange}
          />
        </CardContainer>
      )}
      {current === 1 && (

        <CardContainer>
        <PaymentForm
          {...fields}
          next={next}
          prev={prev}
          onChange={handleFormChange}
        />
        </CardContainer>
      )}
      {current === 0 && (
        <CardContainer>
          <ProfileForm {...fields} next={next} onChange={handleFormChange} />
        </CardContainer>
      )}
      {current === 3 && (
        <SummaryPage summary={summary} prev={prev}/>
      )}
    </React.Fragment>
  );
}

const TwoDemo = Form.create({})(Demo);
export default TwoDemo;