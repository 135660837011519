// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:50ab356e-74b0-482e-82ba-af6f6dbeac01",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_gS8Jb0XLJ",
    "aws_user_pools_web_client_id": "4i9hvd6f1austblhps8mn6j8fm",
    "oauth": {},
    "aws_content_delivery_bucket": "final-react-auth-20200213151922-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d1dbvbxixdqfq0.cloudfront.net"
};


export default awsmobile;
