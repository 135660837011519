import gql from 'graphql-tag';


const MPFILTER = gql`query mpFilter($searchString1: String,$searchString2: String,$searchString3: String, $year: Int, $variable: String, $quarter: String) {
  developments_schema_melted_mp( where: {
    		_and: [
        {year: {_eq: $year}},
        {variable: {_eq: $variable}},
        {quarter: {_eq: $quarter}}
        {condominium: {
          _or: [
            {
              name: {_ilike: $searchString1}
            },
            {
              name: {_ilike: $searchString2}
            },
            {
              name: {_ilike: $searchString3}
            },
            {
              city: {_ilike: $searchString1}
            },
						{
              city: {_ilike: $searchString2}
            },
            {
              city: {_ilike: $searchString3}
            },
            						{
              developer: {_ilike: $searchString1}
                        },
            						{
              developer: {_ilike: $searchString2}
                        },
            						{
              developer: {_ilike: $searchString3}
            }
          ]
        }}
        ]}) {
    id
    condominium {
      name
    }
    variable
    data_1br
    data_2br
    data_3br
    data_4br
    data_5br
    studio
    loft
    penthouse
    researcher_id
    is_updated
    quarter
    year
  }
}`

export default MPFILTER;