import React from "react";
import { withRouter } from 'react-router';
import { Button, Descriptions, Card, Icon, notification } from "antd";
import {useMutation } from '@apollo/react-hooks';
import {CREATE_CONDO} from '../api/mutations/createCondo';
import moment from 'moment';
import {Notices} from './Notices';

const styles = {
  button: {
    float: 'right',
    marginLeft: '10px',
    marginTop: '10px',
    marginBottom: '20px'
  }
}
function SummaryPage (props) {

  const summary = {...props.summary}
  summary.flattened['date_of_research'] = moment(summary.flattened['date_of_research']).format("YYYY-MM-DD");
  // moment(fd, 'MM/DD/YYYY')
  summary.flattened['launch_date'] = moment(summary.flattened['launch_date']).format("YYYY-MM-DD");
  summary.flattened['age_yrs'] = Math.floor(moment.duration(moment(summary.flattened['date_of_research'], "YYYY-MM-DD").diff(moment(summary.flattened['launch_date'], "YYYY-MM-DD"))).as('years') * 100) / 100;
  summary.flattened['age_mos'] = Math.floor(moment.duration(moment(summary.flattened['date_of_research'], "YYYY-MM-DD").diff(moment(summary.flattened['launch_date'], "YYYY-MM-DD"))).as('months') * 100) / 100;


  const [insertCondo, { loading: mutationLoading, error: mutationError }] = useMutation(CREATE_CONDO);
  const openNotificationWithIcon = (type,condo) => {
    notification[type]({
      message: 'Condominium added!',
      description:
        `${condo} has been added! You may now check the database.`,
      duration: 7.5,
    });
  };
  async function handleSubmit() {

    if (!mutationError) {
      await insertCondo({
        variables: {
          name: summary.flattened['name'],
          bldgs: summary.flattened['bldgs'],
          researcher_analyst: summary.flattened['researcher_analyst'],
          date_of_research: summary.flattened['date_of_research'],
          city: summary.flattened['city'],
          developer: summary.flattened['developer'],
          cbd_location: summary.flattened['cbd_location'],
          geo_tagging: summary.flattened['geo_tagging'],
          province: summary.flattened['province'],
          street_and_barangay: summary.flattened['street_and_barangay'],
          number_of_floors: summary.flattened['number_of_floors'],
          lot_area_sqm: summary.flattened['lot_area_sqm'],
          turnover_year: summary.flattened['turnover_year'],
          age_yrs: summary.flattened['age_yrs'],
          age_mos: summary.flattened['age_mos'],
          cash_payment_disc_percent: summary.flattened['cash_payment_disc_percent'],
          deferred_payment_mos: summary.flattened['deferred_payment_mos'],
          pag_ibig_dp: summary.flattened['pag_ibig_dp'],
          pag_ibig_amort: summary.flattened['pag_ibig_amort'],
          reservation_fee: summary.flattened['reservation_fee'],
          full_notes: summary.flattened['full_notes'],
          selling_point_concept: summary.flattened['selling_point_concept'],
          buyers_profile: summary.flattened['buyers_profile'],
          bank_financing_dp_percent: summary.flattened['bank_financing_dp_percent'],
          bank_financing_dp_amort_mos: summary.flattened['bank_financing_dp_amort_mos'],
          bank_financing_bal_amort_mos: summary.flattened['bank_financing_bal_amort_mos'],
          bank_financing_bal_percent: summary.flattened['bank_financing_bal_percent'],
          bank_financing_interest_rate_percent: summary.flattened['bank_financing_interest_rate_percent'],
          ihf_dp_percent: summary.flattened['ihf_dp_percent'],
          ihf_dp_amort_mos: summary.flattened['ihf_dp_amort_mos'],
          ihf_bal_percent: summary.flattened['ihf_bal_percent'],
          ihf_bal_amort_mos: summary.flattened['ihf_bal_amort_mos'],
          ihf_interest_rate: summary.flattened['ihf_interest_rate'],
          miscellaneous_fees: summary.flattened['miscellaneous_fees'],
          association_dues_sqm: summary.flattened['association_dues_sqm'],
          number_of_parking_slots: summary.flattened['number_of_parking_slots'],
          parking_selling_price: summary.flattened['parking_selling_price'],
          comments: summary.flattened['comments'],
          contact_person: summary.flattened['contact_person'],
          contact_number: summary.flattened['contact_number'],
          launch_date: summary.flattened['launch_date'],
          amenities: summary.flattened['amenities'],
          status: 'Updated'
        }
      });
      openNotificationWithIcon('success',summary.flattened['name']);
      props.history.push('/home/condo');
    }
  }
  return (
    
    <React.Fragment>

      {mutationError && <Notices errors={mutationError}/>}
      <Card borderless style={{ padding: 12, background: '#fff'}}>
      {mutationLoading ? (<div style={{textAlign: 'center'}}>
  <Card><Icon type="loading" style={{'fontSize': '50px'}}/></Card>
  </div>): (
    <>
  <Descriptions
        title="Summary"
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
      >
       
        {Object.keys(summary.flattened).map((item) => (
          <Descriptions.Item label={item}>{summary.flattened[item]}</Descriptions.Item>
          // console.log(`Hello ${item}, your value is ${summary.flattened[item]}`)
        ))}
      </Descriptions>
      <Button
          type="primary"
          htmlType="submit" 
          style={styles.button}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          onClick={props.prev}
          style={styles.button}
        >
          Previous
        </Button>
        </>)}

        </Card>
    </React.Fragment>
  );
};

export default withRouter(SummaryPage);