import React from "react";
import { Form, Input, InputNumber, Button, DatePicker } from "antd";

const styles = {
  button: {
    float: 'right',
    marginLeft: '10px'
  }
}
export const ProfileForm = Form.create({
  name: "global_state",
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    return {
      name: Form.createFormField({
        ...props.name,
        value: props.name.value
      }),
      bldgs: Form.createFormField({
        ...props.bldgs,
        value: props.bldgs.value
      }),
      researcher_analyst: Form.createFormField({
        ...props.researcher_analyst,
        value: props.researcher_analyst.value
      }),
      date_of_research: Form.createFormField({
        ...props.date_of_research,
        value: props.date_of_research.value
      }),
      launch_date: Form.createFormField({
        ...props.launch_date,
        value: props.launch_date.value
      }),
      city: Form.createFormField({
        ...props.city,
        value: props.city.value
      }),
      developer: Form.createFormField({
        ...props.developer,
        value: props.developer.value
      }),
      cbd_location: Form.createFormField({
        ...props.cbd_location,
        value: props.cbd_location.value
      }),
      geo_tagging: Form.createFormField({
        ...props.geo_tagging,
        value: props.geo_tagging.value
      }),
      province: Form.createFormField({
        ...props.province,
        value: props.province.value
      }),
      street_and_barangay: Form.createFormField({
        ...props.street_and_barangay,
        value: props.street_and_barangay.value
      }),
      number_of_floors: Form.createFormField({
        ...props.number_of_floors,
        value: props.number_of_floors.value
      }),
      lot_area_sqm: Form.createFormField({
        ...props.lot_area_sqm,
        value: props.lot_area_sqm.value
      }),
      turnover_year: Form.createFormField({
        ...props.turnover_year,
        value: props.turnover_year.value
      }),
      age_yrs: Form.createFormField({
        ...props.age_yrs,
        value: props.age_yrs.value
      }),
      age_mos: Form.createFormField({
        ...props.age_mos,
        value: props.age_mos.value
      }),
    };
  }
})(props => {
  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields
  } = props.form;
  const formItemLayout = {
    labelCol: { xl: 6, lg: 5},
    wrapperCol: { xxl: 14, lg: 13 },
  };
  // { xxl: 4, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }
  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields([
      "name", "bldgs", "researcher_analyst", "status", "date_of_research", "city", "developer", "cbd_location", "launch_date",
      "geo_tagging", "province", "street_and_barangay", "number_of_floors", "lot_area_sqm", "turnover_year", "age_yrs", "age_mos"
  ], (err, values) => {
      if (!err) {
        // console.log(summary)
        // console.log(fields)
        props.next();
      }
    });
  };
  return (
    <Form {...formItemLayout} onSubmit={handleSubmit} >
      <Form.Item
        label="Condominium"
        colon={false}
      >
        {getFieldDecorator("name", {
          rules: [{ required: true, message: "Condominium is required!" }]
        })(<Input  />)}
      </Form.Item>
      <Form.Item
        label="Building No."
        colon={false}
      >
        {getFieldDecorator("bldgs", {
          rules: [{ required: true, message: "Building No. is required!" }]
        })(<InputNumber min={0} max={100} />)}
      </Form.Item>
      <Form.Item
        label="Researcher"
        colon={false}
      >
        {getFieldDecorator("researcher_analyst", {
          rules: [{  required: true, message: "Researcher is required!" }]
        })(<Input />)}
      </Form.Item>
      <Form.Item
        label="Date of Research"
        colon={false}
      >
        {getFieldDecorator("date_of_research", {
          rules: [{ required: true, message: "Date of Research is required!" }]
        })(<DatePicker placeholder="Select date"/>)}
      </Form.Item>
      <Form.Item
        label="Launch Date"
        colon={false}
      >
        {getFieldDecorator("launch_date", { rules: [{required: true, message: "Launch Date is required!"}] })(<DatePicker />)}
      </Form.Item>
      <Form.Item
        label="Developer"
        colon={false}
      >
        {getFieldDecorator("developer", {
          rules: [{ required: true, message: "Developer is required!" }]
        })(<Input />)}
      </Form.Item>
      <Form.Item
        label="City"
        colon={false}
      >
        {getFieldDecorator("city", {
          rules: [{ required: true, message: "City is required!" }]
        })(<Input />)}
 
      </Form.Item>
      <Form.Item
        label="CBD"
        colon={false}
      >
        {getFieldDecorator("cbd_location", {
          rules: [{ required: true, message: "CBD is required!" }]
        })(<Input />)}
 
      </Form.Item>
      <Form.Item
        label="Coordinates"
        colon={false}
      >
        {getFieldDecorator("geo_tagging", {
          rules: [{ required: true, message: "Coordinates is required!" }]
        })(<Input />)}
 
      </Form.Item>
      <Form.Item
        label="Province"
        colon={false}
      >
        {getFieldDecorator("province", {
          rules: [{ required: true, message: "Province is required!" }]
        })(<Input />)}
 
      </Form.Item>
      <Form.Item
        label="Street Address"
        colon={false}
      >
        {getFieldDecorator("street_and_barangay", {
          rules: [{ required: true, message: "Street Address is required!" }]
        })(<Input />)}
 
      </Form.Item>
      <Form.Item
        label="No. of Floors"
        colon={false}
      >
        {getFieldDecorator("number_of_floors", {
          rules: [{ required: true, message: "No. of Floors is required!" }]
        })(<InputNumber min={0} max={100} />)}
 
      </Form.Item>
      <Form.Item
        label="Lot Area (in SQM)"
        colon={false}
      >
        {getFieldDecorator("lot_area_sqm", {
        })(    <InputNumber
          defaultValue={100}
          min={0}
          formatter={value => `${value}\u33A1`}
          parser={value => value.replace(`\u33A1`, '')}
        />)}
 
      </Form.Item>
      <Form.Item
        label="Turnover Year"
        colon={false}
      >
        {getFieldDecorator("turnover_year", {
          rules: [{required: true, message: "Turnover Year is required!" }]
        })(<InputNumber min={1900} />)}
 
      </Form.Item>
      <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
          style={styles.button}
        >
          Next
        </Button>
    </Form>
  );
});
