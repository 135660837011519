import React from "react";
import { Card, Col, Row, Icon, Statistic} from 'antd';
import { AreaChart, Area,CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Legend, Bar, Funnel, FunnelChart, LabelList} from 'recharts';
const data = [
  {
    "name": "2016",
    "uv": 4000,
    "pv": 2400,
    "amt": 2400
  },
  {
    "name": "2017",
    "uv": 3000,
    "pv": 1398,
    "amt": 2210
  },
  {
    "name": "2018",
    "uv": 2000,
    "pv": 9800,
    "amt": 2290
  },
  {
    "name": "2019",
    "uv": 2780,
    "pv": 3908,
    "amt": 2000
  },
  {
    "name": "2020",
    "uv": 1890,
    "pv": 4800,
    "amt": 2181
  }
]
const data2 = [
  {
    "value": 100,
    "name": "Class A",
    "fill": "#8884d8"
  },
  {
    "value": 80,
    "name": "Class B",
    "fill": "#83a6ed"
  },
  {
    "value": 50,
    "name": "Class C",
    "fill": "#8dd1e1"
  },
  {
    "value": 40,
    "name": "Class D",
    "fill": "#82ca9d"
  },
  {
    "value": 26,
    "name": "Class E",
    "fill": "#a4de6c"
  }
]

function Home() {
    return (
        <div style={{ padding: '30px', minHeight: '100vh'}}>
        <Row gutter={[16, 16]} type="flex">
          <Col span={8}>
            <Card hoverable={true} bordered={false} style={{justifyContent: 'center'}}>
            <AreaChart width={400} height={200} data={data}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#273896" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#273896" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#D94423" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#D94423" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey="uv" stroke="#273896" fillOpacity={1} fill="url(#colorUv)" />
            <Area type="monotone" dataKey="pv" stroke="#D94423" fillOpacity={1} fill="url(#colorPv)" />
          </AreaChart>
            </Card>
            </Col>
            <Col span={8}>
            <Card title="Market Performance" hoverable={true} bordered={false} style={{justifyContent: 'center'}}>
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title="Active"
                    value={11.28}
                    precision={2}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<Icon type="arrow-up" />}
                    suffix="%"
                  />
                </Card>
              </Col>
              <Col span={12}>
                  <Card bordered={false}>
                    <Statistic
                      title="Idle"
                      value={9.3}
                      precision={2}
                      valueStyle={{ color: '#cf1322' }}
                      prefix={<Icon type="arrow-down" />}
                      suffix="%"
                    />
                  </Card>
                </Col>
            </Card>
            </Col>
            <Col span={8}>
            <Card hoverable={true} bordered={false} style={{justifyContent: 'center'}}>
            <BarChart width={400} height={200} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#273896" />
              <Bar dataKey="uv" fill="#D94423" />
            </BarChart>
            </Card>
            </Col>
        </Row>
        <Row gutter={[16, 16]} type="flex" justify="center">
          <Col span={24}>
            <Card hoverable={true} bordered={false} style={{justifyContent: 'center', display: 'flex', margin: 'auto'}}>
              <FunnelChart width={900} height={400}>
              <Tooltip />
              <Funnel
                dataKey="value"
                data={data2}
                isAnimationActive
              >
                <LabelList position="right" fill="#000" stroke="none" dataKey="name" />
              </Funnel>
            </FunnelChart>
            </Card>
            </Col>
        </Row>
      </div>
      
    )
}

export default Home;