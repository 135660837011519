import gql from 'graphql-tag';

export const CREATE_CONDO = gql`mutation createCondo(
  $name: String!, 
  $bldgs: Int!, 
  $researcher_analyst: String!, 
  $date_of_research: date!, 
  $city: String!, $developer: String!, 
  $cbd_location: String!, 
  $geo_tagging: String!, 
  $province: String!,
  $status: String,
  $street_and_barangay: String!, 
  $number_of_floors: bigint!, 
  $lot_area_sqm: float8!, 
  $turnover_year: bigint!, 
  $age_yrs: float8!, 
  $age_mos: float8!, 
  $cash_payment_disc_percent: float8, 
  $deferred_payment_mos: float8, 
  $pag_ibig_dp: float8, 
  $pag_ibig_amort: float8, 
  $reservation_fee: float8, 
  $full_notes: String, 
  $selling_point_concept: String, 
  $buyers_profile: String, 
  $bank_financing_dp_percent: float8, 
  $bank_financing_dp_amort_mos: float8, 
  $bank_financing_bal_percent: float8, 
  $bank_financing_bal_amort_mos: float8, 
  $bank_financing_interest_rate_percent: float8, 
  $ihf_dp_amort_mos: float8, 
  $ihf_bal_percent: float8, 
  $ihf_bal_amort_mos: float8, 
  $ihf_dp_percent: float8, 
  $ihf_interest_rate: float8, 
  $miscellaneous_fees: float8, 
  $association_dues_sqm: Int, 
  $number_of_parking_slots: float8, 
  $parking_selling_price: Int, 
  $comments: String, 
  $contact_person: String, 
  $contact_number: String, 
  $launch_date: date, 
  $amenities: String) {
  insert_developments_schema_condominium(objects: {
    age_mos: $age_mos, 
    age_yrs: $age_yrs, 
    amenities: $amenities, 
    association_dues_sqm: $association_dues_sqm,
    bank_financing_bal_amort_mos: $bank_financing_bal_amort_mos, 
    bank_financing_bal_percent: $bank_financing_bal_percent, 
    bank_financing_dp_amort_mos: $bank_financing_dp_amort_mos, 
    bank_financing_dp_percent: $bank_financing_dp_percent, 
    bank_financing_interest_rate_percent: $bank_financing_interest_rate_percent, 
    bldgs: $bldgs, 
    buyers_profile: $buyers_profile, 
    cash_payment_disc_percent: $cash_payment_disc_percent, 
    cbd_location: $cbd_location, 
    city: $city, 
    comments: $comments, 
    contact_number: $contact_number, 
    contact_person: $contact_person, 
    date_of_research: $date_of_research, 
    deferred_payment_mos: $deferred_payment_mos,
    developer: $developer, 
    full_notes: $full_notes,
    geo_tagging: $geo_tagging, 
    ihf_bal_amort_mos: $ihf_bal_amort_mos, 
    ihf_balance_percent: $ihf_bal_percent, 
    ihf_dp_amort_mos: $ihf_dp_amort_mos, 
    ihf_interest_rate: $ihf_interest_rate, 
    ihf_dp_percent: $ihf_dp_percent, 
    launch_date: $launch_date, 
    lot_area_sqm: $lot_area_sqm,
    miscellaneous_fees: $miscellaneous_fees, 
    name: $name, 
    number_of_floors: $number_of_floors, 
    number_of_parking_slots: $number_of_parking_slots, 
    pag_ibig_amort: $pag_ibig_amort, 
    pag_ibig_dp: $pag_ibig_dp, 
    parking_selling_price: $parking_selling_price, 
    province: $province, 
    researcher_analyst: $researcher_analyst, 
    reservation_fee: $reservation_fee, 
    selling_point_concept: $selling_point_concept, 
    status: $status, 
    street_and_barangay: $street_and_barangay, 
    turnover_year: $turnover_year
  }) {
    affected_rows
  }
}`