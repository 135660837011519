import React, { useState, useEffect } from 'react';
import { Switch, Route, Link } from "react-router-dom";
import {Layout, Menu, Icon, PageHeader, Button, Avatar, Dropdown} from 'antd';
import EditableFormTable from './components/editableFormTable';
import Home from './Home';
import NewCondoTable from './components/NewCondoTable';
import { Auth } from 'aws-amplify';
import {withAuthenticator} from 'aws-amplify-react';
import Profile from './Profile';
import TwoDemo from './components/NewAddCondo';
import AddNewMps from './components/AddHistoricData';
const { Sider, Footer, Content } = Layout;

const Main = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState('');


  useEffect(() => {
    try {
      setError(null);
      setLoading(true);

      Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        setFirstName(user.attributes.given_name);
      }).catch(err => setError(err));
    }
    catch (e) {
      setError(e);
    }
    finally {
      setLoading(false);
    }
  }, []);
  const handleLogOut = async () => {
    try {
      await Auth.signOut();
      props.auth.setIdToken(null);
      props.history.push('/login');
    }catch(error) {
      console.log(error.message);
    }
  }
  const menu = () => (
    <Menu>
        <Menu.Item key="1" >
        <Button type="link" icon="user" href="/home/profile">
          Profile
        </Button>
      </Menu.Item> 
      <Menu.Item key="2" onClick={handleLogOut}>
        <Button type="link" icon="logout">
        Log-out
        </Button>
      </Menu.Item> 

    </Menu>
  );

    return (
      
      <Layout style={{ minHeight: '100vh' }}>
      <Sider
      collapsible
      breakpoint="lg"
      collapsedWidth="0"
    >
        <Menu theme="dark" mode="inline">
        <div style={{padding: '20px', textAlign: 'center'}}>
        <img src='https://amplify-final-react-auth-dev-171957-deployment.s3.us-east-2.amazonaws.com/white-lcp-logo.png' alt='Leechiu Property Consultants' style={{display: 'flex', marginLeft: 'auto', marginRight: 'auto', width: '80%'}}/>
        </div>
        <Menu.Item key="1">
        <Icon type="bar-chart" />
              <span>Dashboard</span>
              <Link to='/home/dashboard'/>
          </Menu.Item>
          <Menu.Item key="2">
          <Icon type="profile" />
              <span>Condominium</span>
              <Link to='/home/condo'/>
          </Menu.Item>
          <Menu.Item key="3">
            <Icon type="desktop" />
            <span>Update</span>
            <Link to='/home/update'/>
          </Menu.Item>
          <Menu.Item key="4">
          <Icon type="double-right" />
            <span>Generate</span>
            <Link to='/home/generate'/>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <PageHeader
            style={{
              border: '1px solid rgb(235, 237, 240)',
              background: '#fff', 
              justifyContent: 'center'
            }}
            title="Residential"
            extra={[
                <>
                  <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                    <div style={{display:'flex',justifyContent: 'center'}}>
                      <h4> {firstName} <Icon type="down" /></h4>
                      </div>
                  </Dropdown>
                </>
            ]}
          />
        <Content style={{ margin: '10px 16px', overflow: 'auto' }}>

            <>
            <Switch>
              <Route exact path="/home/dashboard" component={Home}/>
              <Route exact path="/home/add" component={TwoDemo}/>

              <Route path="/home/condo" component={NewCondoTable}/>
              <Route path="/home/profile" component={Profile}/>
              <Route path="/home/update" component={EditableFormTable} />
              <Route path="/home/generate" component={AddNewMps}/>

            </Switch>
            </>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Developed by Leechiu Data Analytics Team | Ant Design ©2019</Footer>
      </Layout>
    </Layout>
    );
}
export default withAuthenticator(Main);
