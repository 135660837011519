import React from "react";
import { Form, Input, InputNumber, Button, DatePicker, Select } from "antd";
const { Option } = Select;

const styles = {
  button: {
    float: 'right',
    marginLeft: '10px'
  }
}
export const MiscForm = Form.create({
  name: "global_state",
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    return {
      miscellaneous_fees: Form.createFormField({
        ...props.miscellaneous_fees,
        value: props.miscellaneous_fees.value
      }),
      association_dues_sqm: Form.createFormField({
        ...props.association_dues_sqm,
        value: props.association_dues_sqm.value
      }),
      number_of_parking_slots: Form.createFormField({
        ...props.number_of_parking_slots,
        value: props.number_of_parking_slots.value
      }),
      parking_selling_price: Form.createFormField({
        ...props.parking_selling_price,
        value: props.parking_selling_price.value
      }),
      comments: Form.createFormField({
        ...props.comments,
        value: props.comments.value
      }),
      contact_person: Form.createFormField({
        ...props.contact_person,
        value: props.contact_person.value
      }),
      contact_number: Form.createFormField({
        ...props.contact_number,
        value: props.contact_number.value
      }),
      launch_date: Form.createFormField({
        ...props.launch_date,
        value: props.launch_date.value
      }),
      amenities: Form.createFormField({
        ...props.amenities,
        value: props.amenities.value
      })
    };
  }
})(props => {
  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields
  } = props.form;
  const prefixSelector = getFieldDecorator('prefix', {
    initialValue: '+63',
  })(
    <Select style={{ width: 70 }}>
      <Option value="+63">+63</Option>
    </Select>,
  );
  const handleSubmit = e => {
    e.preventDefault();

    validateFields(["miscellaneous_fees", "association_dues_sqm", "number_of_parking_slots", "parking_selling_price",
  "comments", "contact_person", "contact_number",  "amenities"], (err, values) => {
      if (!err) {
        props.fieldsFlattener();
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item
        label="Miscellaneous Fees"
        colon={false}
      >
        {getFieldDecorator("miscellaneous_fees", {
        })(<InputNumber
          min={0}
          formatter={value => `\u20b1 ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\u20b1\s?|(,*)/g, '')}
        />)}
      </Form.Item>
      <Form.Item
        label="Association Dues (in PHP/SQM)"
        colon={false}
      >
        {getFieldDecorator("association_dues_sqm", {
        })
        (<InputNumber
          defaultValue={100}
          min={0}
          formatter={value => `\u20b1 ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\u20b1\s?|(,*)/g, '')}
        />)}
      </Form.Item>
      <Form.Item
        label="No. of Parking Slots"
        colon={false}
      >
        {getFieldDecorator("number_of_parking_slots", {
        })(<InputNumber min={0} />)}
      </Form.Item>
      <Form.Item
        label="Parking Selling Price"
        colon={false}
      >
        {getFieldDecorator("parking_selling_price", {
        })(<InputNumber
          min={0}
          formatter={value => `\u20b1 ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\u20b1\s?|(,*)/g, '')}
        />)}
      </Form.Item>
      <Form.Item
        label="Comments"
        colon={false}
      >
        {getFieldDecorator("comments", {
        })(<Input />)}
      </Form.Item>
      <Form.Item
        label="Contact Person"
        colon={false}
      >
        {getFieldDecorator("contact_person", {
        })(<Input />)}
      </Form.Item>
      <Form.Item
        label="Contact Number"
        colon={false}
      >
        {getFieldDecorator("contact_number", {
        })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} />)}
      </Form.Item>
      <Form.Item label="Amenities" colon={false}>
        {getFieldDecorator("amenities", {
        })(<Input/>)}
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        disabled={hasErrors(getFieldsError())}
        style={styles.button}
      >
        Next
      </Button>
      <Button onClick={props.prev} style={styles.button}>
        Previous
      </Button>
    </Form>
  );
});
