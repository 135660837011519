import React, { useState } from 'react';
import { Form, Input, Button, Row, Card, Select} from 'antd';
import {Auth} from 'aws-amplify';
import { Notices } from "../components/Notices";

const { Option } = Select;
const SignUp = (props) => {
    const [errors, setErrors] = useState({errors: {
        message: ""
    }});
    const [user, setUser] = useState("");
    const [signedUp, setSignedUp] = useState(false);
    const switchToVerify = () => {
      var isSignedUp = true;
      setSignedUp(isSignedUp);
    }
    const handleVerify = e => {
      e.preventDefault();
      props.form.validateFields(async (err, values) => {
        const {verification_code} = values;
        if (!err) {

                await Auth.confirmSignUp(user, verification_code).then(props.history.push("/login")).catch(cognitoerror => {
                  let cognitoerr = null;
                  !cognitoerror.message ? cognitoerr = {"message": cognitoerror} : cognitoerr = cognitoerror;
                  setErrors({...cognitoerr});
                }
                );
        }
        else {
          let cognitoerr = null;
          !err.message ? cognitoerr = {"message": err} : cognitoerr = err;
          setErrors({...cognitoerr});
        }
      });
    };
    const handleSubmit = e => {
      e.preventDefault();
      props.form.validateFields(async (err, values) => {
        const {username, password, email, phone, first_name, last_name, prefix} = values;
        var lower_user = username.toLowerCase().trim();
        if (!err) {
          try {
            await Auth.signUp({
                username: lower_user,
                password,
                attributes: {
                    given_name: first_name.trim(),
                    family_name: last_name.trim(),
                    email: email.trim(),
                    phone_number: `${prefix}${phone}`    
                }
            }).catch(cognitoerror => {
              let cognitoerr = null;
              !cognitoerror.message ? cognitoerr = {"message": cognitoerror} : cognitoerr = cognitoerror;
              setErrors({...cognitoerr});
            }
            );
            setUser(lower_user);
            switchToVerify();
            // props.history.push("/login");
          }
          catch (cognitoerror) {
              let cognitoerr = null;
              
              !cognitoerror.message ? cognitoerr = {"message": cognitoerror} : cognitoerr = cognitoerror;
              setErrors({...cognitoerr});
              
          }
          

        }

      });
    };
    const [dirty, setDirty] = useState(false);
    const handleConfirmBlur = e => {
        const { value } = e.target;
        setDirty(true || !!value);
      };
    const compareToFirstPassword = (rule, value, callback) => {
        const { form } = props;
        if (value && value !== form.getFieldValue('password')) {
          callback('Two passwords that you enter is inconsistent!');
        } else {
          callback();
        }
      };
      const emailWhitelist = (rule, value, callback) => {
        const { form } = props;
        const re = new RegExp("^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\\.)?[a-zA-Z]+\\.)?(leechiu)\\.com$")
        if (!re.test(form.getFieldValue('email'))) {
          callback('E-mail must have Leechiu domain!');
        } else {
          callback();
        }
      };
      const verificationCodePattern = (rule, value, callback) => {
        const { form } = props;
        const re = new RegExp("^[0-9\\b]{6}$")
        if (!re.test(form.getFieldValue('verification_code'))) {
          callback('Verification codes are all numbers and must be at least 6 numbers.');
        } else {
          callback();
        }
      };
    
    const validateToNextPassword = (rule, value, callback) => {
        const { form } = props;
        if (value && dirty) {
          form.validateFields(['confirm'], { force: true });
        }
        callback();
      };
    const { getFieldDecorator } = props.form;
    const prefixSelector = getFieldDecorator('prefix', {
        initialValue: '+63',
      })(
        <Select style={{ width: 70 }}>
          <Option value="+63">+63</Option>
        </Select>,
      );
    return (
      <>
      <Notices errors={errors}/>
      {!signedUp ? ( <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}} >
          <Card title="Sign-up for REAPER" style={{width: '375px', height: 'auto'}}>
      <Form onSubmit={handleSubmit}>
      <Form.Item label="Username" extra="Note: Type your employee ID in all lowercase letters.">
        {getFieldDecorator('username', {
          rules: [{ required: true, message: 'Please input your username!' }],
        })(
          <Input
          />,
        )}
      </Form.Item>
      <Form.Item label="Password" hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                pattern: new RegExp("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$"),
                message: 'Must have at least 8 letters, one special character, and a number',
              },
              {
                validator: validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item label="Confirm Password" hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={handleConfirmBlur} />)}
        </Form.Item>
        <Form.Item
          label="First Name"
        >
          {getFieldDecorator('first_name', {
            rules: [{ required: true, message: 'Please input your First Name!', whitespace: true }],
          })(<Input />)}
        </Form.Item>
        <Form.Item
          label="Last Name"
        >
          {getFieldDecorator('last_name', {
            rules: [{ required: true, message: 'Please input your Last Name!', whitespace: true }],
          })(<Input />)}
        </Form.Item>
      <Form.Item label="E-mail">
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
              {
                validator: emailWhitelist
              }
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Phone Number">
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'Please input your phone number!' }],
          })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} />)}
        </Form.Item>
      <Form.Item>

        <Button 
        type="primary" 
        htmlType="submit" 
        style={{width: '100%'}}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>


    </Card>
    <Button 
        type="link" 
        href="/login"
        style={{margin: '10 px', width: '100%'}}
        >
        Cancel
        </Button>
    </Row>) : 
    //Verify Code here
    <>
    <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}} >
        <Card title="Confirm your REAPER Account" style={{width: '375px', height: 'auto'}}>
        <Form onSubmit={handleVerify}>
        <h2>Username: {user}</h2>
        <Form.Item label="Verification Code">
        {getFieldDecorator("verification_code", {
          rules: [
            {
              required: true,
              message: "This is a required field."
            },
            {
              validator: verificationCodePattern
            }
          ],
        })(        <Input
          placeholder="Input a number"
          maxLength={25}
        />)}
        </Form.Item>

        <Button 
        type="primary" 
        htmlType="submit" 
        style={{width: '100%'}}
        >
        Submit
        </Button>
        </Form>
        <Button 
        type="link" 
        onClick={() => Auth.resendSignUp(user)}
        >
          Resend Code
        </Button>
        </Card>
    </Row>
    </>}
    </>
    );
    };
  const WrappedSignUpForm = Form.create({ name: 'sign_up' })(SignUp);


export default WrappedSignUpForm;