import gql from 'graphql-tag';

const INSERT_BULK_MPS = gql`mutation insertBulkMps($condo_id: uuid!, $quarter: String!, $year: Int!, $researcher_id: String!) {
    insert_developments_schema_melted_mp(
      objects: [
        {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Remaining Inventory"
        },
              {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Average Unit Size"
              },
              {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Sold Units"
              },
              {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Sales Velocity"
              },
              {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Minimum Package Price"
              },
              {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Maximum Size"
              },
              {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Maximum Price per SQM (in PHP)"
              },
              {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Average Price per SQM (in PHP)"
              },
              {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Number of Bedrooms"
              },
              {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Average Package Price"
              },
              {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Maximum Package Price"
              },
              {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Total Units Per Type"
              },
        {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Minimum Size"
        },
        {
          condo_id: $condo_id,
          quarter: $quarter,
          year: $year,
          researcher_id: $researcher_id,
          variable: "Minimum Price per SQM (in PHP)"
        }
      ]
    ) {
      affected_rows
    }
  }`

export default INSERT_BULK_MPS;