import gql from 'graphql-tag';


const UPDATE_RESI_TABLE = gql`mutation UpdateCurrentTable($id: Int!, $data_1br: float8, $data_2br: float8, $data_3br: float8, $data_4br: float8, $data_5br: float8, $studio: float8, $loft: float8, $penthouse: float8, $researcher_id: String) {
  update_developments_schema_melted_mp(where: {
    id: {_eq: $id}
  }, _set: {
    data_1br: $data_1br, 
    data_2br: $data_2br,
    data_3br: $data_3br, 
    data_4br: $data_4br, 
    data_5br: $data_5br, 
    loft: $loft, 
    penthouse: $penthouse, 
    researcher_id: $researcher_id}) {
    returning {
      condo_id
    }
  }
}`

export default UPDATE_RESI_TABLE;