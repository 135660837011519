import React from "react";
import { Form, Input, Button, InputNumber } from "antd";

const styles = {
  button: {
    float: 'right',
    marginLeft: '10px'
  }
}
export const PaymentForm = Form.create({
  name: "global_state",
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    return {
      
      cash_payment_disc_percent: Form.createFormField({
        ...props.cash_payment_disc_percent,
        value: props.cash_payment_disc_percent.value
      }),
      deferred_payment_mos: Form.createFormField({
        ...props.deferred_payment_mos,
        value: props.deferred_payment_mos.value
      }),
      pag_ibig_dp: Form.createFormField({
        ...props.pag_ibig_dp,
        value: props.pag_ibig_dp.value
      }),
      pag_ibig_amort: Form.createFormField({
        ...props.pag_ibig_amort,
        value: props.pag_ibig_amort.value
      }),
      reservation_fee: Form.createFormField({
        ...props.reservation_fee,
        value: props.reservation_fee.value
      }),
      full_notes: Form.createFormField({
        ...props.full_notes,
        value: props.full_notes.value
      }),
      selling_point_concept: Form.createFormField({
        ...props.selling_point_concept,
        value: props.selling_point_concept.value
      }),
      buyers_profile: Form.createFormField({
        ...props.buyers_profile,
        value: props.buyers_profile.value
      }),
      bank_financing_dp_percent: Form.createFormField({
        ...props.bank_financing_dp_percent,
        value: props.bank_financing_dp_percent.value
      }),
      bank_financing_dp_amort_mos: Form.createFormField({
        ...props.bank_financing_dp_amort_mos,
        value: props.bank_financing_dp_amort_mos.value
      }),
      bank_financing_bal_percent: Form.createFormField({
        ...props.bank_financing_bal_percent,
        value: props.bank_financing_bal_percent.value
      }),
      bank_financing_bal_amort_mos: Form.createFormField({
        ...props.bank_financing_bal_amort_mos,
        value: props.bank_financing_bal_amort_mos.value
      }),
      bank_financing_interest_rate_percent: Form.createFormField({
        ...props.bank_financing_interest_rate_percent,
        value: props.bank_financing_interest_rate_percent.value
      }),
      ihf_dp_percent: Form.createFormField({
        ...props.ihf_dp_percent,
        value: props.ihf_dp_percent.value
      }),
      ihf_dp_amort_mos: Form.createFormField({
        ...props.ihf_dp_amort_mos,
        value: props.ihf_dp_amort_mos.value
      }),
      ihf_bal_percent: Form.createFormField({
        ...props.ihf_bal_percent,
        value: props.ihf_bal_percent.value
      }),
      ihf_bal_amort_mos: Form.createFormField({
        ...props.ihf_bal_amort_mos,
        value: props.ihf_bal_amort_mos.value
      }),
      ihf_interest_rate: Form.createFormField({
        ...props.ihf_interest_rate,
        value: props.ihf_interest_rate.value
      }),
    };
  }
})(props => {
  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields
  } = props.form;
  const handleSubmit = e => {
    e.preventDefault();

    validateFields(["bank_financing_dp_percent", "bank_financing_dp_amort_mos", "bank_financing_bal_percent", 
    "bank_financing_bal_amort_mos", "bank_financing_interest_rate_percent", "ihf_dp_percent", "ihf_dp_amort_mos",
    "ihf_bal_percent", "ihf_bal_amort_mos", "ihf_interest_rate", "cash_payment_disc_percent", "deferred_payment_mos", "pag_ibig_dp", "pag_ibig_amort",
    "reservation_fee", "full_notes", "selling_point_concept", "buyers_profile"], (err, values) => {
      if (!err) {
        // console.log(summary)
        // console.log(fields)
        props.next();
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item
        label="Payment Discount %"
        colon={false}
      >
        {getFieldDecorator("cash_payment_disc_percent", {
          
        })(    <InputNumber
          min={0}
          max={100}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
        />)}
      </Form.Item>
      <Form.Item
        label="Deferred Payment (in Months)"
        colon={false}
      >
        {getFieldDecorator("deferred_payment_mos", {
        })(<InputNumber min={0}/>)}
      </Form.Item>
      <Form.Item
        label="Pag-Ibig DP"
        colon={false}
      >
        {getFieldDecorator("pag_ibig_dp", {
          
        })(<InputNumber min={0}/>)}
      </Form.Item>
      <Form.Item
        label="Pag-Ibig Amortization (in Months)"
        colon={false}
      >
        {getFieldDecorator("pag_ibig_amort", {
          
        })(<InputNumber min={0}/>)}
      </Form.Item>
      <Form.Item label="Reservation Fee" colon={false}>
        {getFieldDecorator("reservation_fee", {
        })(<InputNumber min={0}/>)}
      </Form.Item>
     
      <Form.Item
        label="BF DP %"
        colon={false}
      >
        {getFieldDecorator("bank_financing_dp_percent", {
          
        })( <InputNumber
          defaultValue={100}
          min={0}
          max={100}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
        />)}
      </Form.Item>
      <Form.Item
        label="BF DP Amortization (in Months)"
        colon={false}
      >
        {getFieldDecorator("bank_financing_dp_amort_mos", {
          
        })(<InputNumber min={0} max={99}/>)}
      </Form.Item>
      <Form.Item
        label="BF Balance %"
        colon={false}
      >
        {getFieldDecorator("bank_financing_bal_percent", {
        })( <InputNumber
          defaultValue={100}
          min={0}
          max={100}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
        />)}
      </Form.Item>
      <Form.Item
        label="BF Balance Amortization (in Months)"
        colon={false}
      >
        {getFieldDecorator("bank_financing_bal_amort_mos", {
        })(<InputNumber min={0}/>)}
      </Form.Item>
      <Form.Item
        label="BF DP Interest Rate %"
        colon={false}
      >
        {getFieldDecorator("bank_financing_interest_rate_percent", {
          
        })(<InputNumber
          defaultValue={100}
          min={0}
          max={100}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
        />)}
      </Form.Item>
      <Form.Item
        label="IHF DP %"
        colon={false}
      >
        {getFieldDecorator("ihf_dp_percent", {
          
        })(<InputNumber
          defaultValue={100}
          min={0}
          max={100}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
        />)}
      </Form.Item>
      <Form.Item
        label="IHF DP Amortization (in Months)"
        colon={false}
      >
        {getFieldDecorator("ihf_dp_amort_mos", {
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label="IHF Balance %" colon={false}>
        {getFieldDecorator("ihf_bal_percent", {
        })(<InputNumber
          defaultValue={100}
          min={0}
          max={100}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
        />)}
      </Form.Item>
      <Form.Item
        label="IHF Balance Amortization (in Months)"
        colon={false}
      >
        {getFieldDecorator("ihf_bal_amort_mos", {
        })(<InputNumber min={0}/>)}
      </Form.Item>
      <Form.Item
        label="IHF DP Interest Rate %"
        colon={false}
      >
        {getFieldDecorator("ihf_interest_rate", {
          
        })( <InputNumber
          defaultValue={100}
          min={0}
          max={100}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
        />)}
      </Form.Item>
      <Form.Item
        label="Full Notes"
        colon={false}
      >
        {getFieldDecorator("full_notes", {
          rules: [{initialValue: ""}]
        })(<Input />)}
      </Form.Item>
      <Form.Item
        label="Selling Point Concept"
        colon={false}
      >
        {getFieldDecorator("selling_point_concept", {
          rules: [{ initialValue: ""}]
        })(<Input />)}
      </Form.Item>
      <Form.Item
        label="Buyers' Profile"
        colon={false}
      >
        {getFieldDecorator("buyers_profile", {
          rules: [{ initialValue: "" }]
        })(<Input />)}
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        disabled={hasErrors(getFieldsError())}
        style={styles.button}
      >
        Next
      </Button>
      <Button onClick={props.prev} style={styles.button}>
        Previous
      </Button>

    </Form>
  );
});
