import React, { useState } from 'react';
import { Form, Input, Button, Icon, Checkbox, Row, Card, message} from 'antd';
import {Auth} from 'aws-amplify';
import { Notices } from "../components/Notices";

const MySignIn = (props) => {
    const [errors, setErrors] = useState({errors: {
        message: ""
    }});
    const handleSubmit = e => {
      e.preventDefault();
      props.form.validateFields(async (err, values) => {
        const {username, password} = values;
        if (!err) {
                Auth.signIn(username, password).then(user => {props.auth.setIdToken(user.signInUserSession.idToken.jwtToken); message.success(`You're now logged in as: ${username}!`); props.history.push("/home/dashboard");}).catch(cognitoerror => {
                let cognitoerr = null;
                !cognitoerror.message ? cognitoerr = {"message": cognitoerror} : cognitoerr = cognitoerror;
                setErrors({...cognitoerr});
              }
                );
        }
        else {
          let cognitoerr = null;
          !err.message ? cognitoerr = {"message": err} : cognitoerr = err;
          setErrors({...cognitoerr});
        }
      }
      )};
              
    const { getFieldDecorator } = props.form;
    return (
      <>
      <Notices errors={errors}/>
      <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}} >

          <Card style={{width: '375px', height: 'auto'}}>
        <img src="https://amplify-final-react-auth-dev-171957-deployment.s3.us-east-2.amazonaws.com/logo-hires-transparent.png" alt='Leechiu Property Consultants' style={{display: 'flex', margin: '0 auto', padding: 15, width: '65%'}}/>

      <Form onSubmit={handleSubmit}>
      <Form.Item extra="Note: Type your employee ID">
        {getFieldDecorator('username', {
          rules: [{ required: true, message: 'Please input your username!' }],
        })(
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Username"
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [{ required: true, message: 'Please input your Password!' }],
        })(
          <Input.Password
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Password"
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('remember', {
          valuePropName: 'checked',
          initialValue: true,
        })(<Checkbox>Remember me</Checkbox>)}
        <a href="/forgotpassword" style={{float: 'right'}}>
          Forgot password
        </a>
        <Button 
        type="primary" 
        htmlType="submit" 
        style={{width: '100%'}}
        >
          Log in
        </Button>
        Or <a 
        href="/signup">register now!</a>
      </Form.Item>
    </Form>
    </Card>
    </Row>
    </>
    );
    };
  const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(MySignIn);


export default WrappedNormalLoginForm;