import React, { useState, useEffect } from "react";
import "./App.css";
// for routing
import { Switch, Route, Redirect } from "react-router-dom";

// for apollo client
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";
import { setContext } from "apollo-link-context";
import Main from "./Main";
import { Auth } from 'aws-amplify';
import WrappedNormalLoginForm from "./auth/Login";
import WrappedSignUpForm from "./auth/SignUp";
import WrappedForgotPasswordForm from "./auth/ForgotPassword";


function App() {

  const [idToken, setIdToken] = useState("");


  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_ENDPOINT}`
  });

  const authLink = setContext((_, { headers }) => {
    const token = idToken;
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      };
    } else {
      return {
        headers: {
          ...headers
        }
      };
    }
  });

    const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });
  
  useEffect(() => {
    Auth.currentSession()
      .then(user => setIdToken(user.idToken.jwtToken ))
      .catch(error => console.log(error) )
  })

  const authProps = {
    setIdToken: setIdToken
  }
  return (
    <ApolloProvider client={client}>
      
      <Switch>
        <Route exact path="/login" render={(props) => <WrappedNormalLoginForm {...props} auth={authProps} />}/>
        <Route exact path="/signup" component={WrappedSignUpForm}/>
        <Route exact path="/forgotpassword" component={WrappedForgotPasswordForm}/>
        <Route path="/home/" render={(props) => <Main {...props} auth={authProps} />}/>
        <Route path="/*" render={() => <Redirect to="/login" />}/>

      </Switch>
    </ApolloProvider>
  );

}

export default App;