import React from "react";
import {Alert} from 'antd';


export const Notices = (props) => {
  if (
    props.errors &&
    props.errors.message
  ) {
    return (
      <>
        <Alert
      message="Something went wrong."
      description={props.errors.message}
      type="error"
      banner
      closable
    />
      </>
    )
  
} 
else {
  return (
    <>
    </>
  )
}
};