import React from 'react';
import { Table, Icon, Button, Input, InputNumber, Popconfirm, Form, Select, Card} from 'antd';
import MPFILTER from '../api/queries/mpFilter';
import ALL_MELTEDMPS from '../api/queries/allMeltedMps';
import MPFILTERNOVAR from '../api/queries/mpFilterNoVariable';

import { Query, Mutation } from 'react-apollo';
import UPDATE_RESI_TABLE from '../api/mutations/UpdateCurrentTable';
import { CSVLink} from "react-csv";
import { Auth } from 'aws-amplify';



const { Option } = Select;
const EditableContext = React.createContext();


class EditableCell extends React.Component {
  state = {
    user: {}
  }
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input/>;
  };


  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      state,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              initialValue: record[dataIndex],
              inputType: 'number'
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}
class UpdateTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editingKey: '',
      latestEdit: [],
      searchString: '',
      year: 2020,
      variable: '',
      username: '',
      quarter: '',
      user: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.columns = [
    {
      title: 'Condominium',
      dataIndex: 'condominium',
      key: 'condominium',
      width: '25%',
    },
    {
      title: 'Metric',
      dataIndex: 'variable',
      key: 'variable',
      width: '20%'
    },
    {
      title: 'Studio',
      dataIndex: 'studio',
      key: 'studio',
      width: '40%',
      editable: true,
      render: (text) => {
        if (text === null) {
          return ''
        }
        return text.toLocaleString()}
    },
    {
      title: '1BR',
      dataIndex: 'data_1br',
      key: 'data_1br',
      width: '15%',
      editable: true,
      render: (text) => {
        if (text === null) {
          return ''
        }
        return text.toLocaleString()}
    },
    {
      title: '2BR',
      dataIndex: 'data_2br',
      key: 'data_2br',
      width: '15%',
      editable: true,
      render: (text) => {
        if (text === null) {
          return ''
        }
        return text.toLocaleString()}

    },
    {
      title: '3BR',
      dataIndex: 'data_3br',
      key: 'data_3br',
      width: '15%',
      editable: true,
      render: (text) => {
        if (text === null) {
          return ''
        }
        return text.toLocaleString()}
    },
    {
      title: '4BR',
      dataIndex: 'data_4br',
      key: 'data_4br',
      width: '15%',
      editable: true,
      render: (text) => {
        if (text === null) {
          return ''
        }
        return text.toLocaleString()}

    },
    {
      title: '5BR',
      dataIndex: 'data_5br',
      key: 'data_5br',
      width: '15%',
      editable: true,
      render: (text) => {
        if (text === null) {
          return ''
        }
        return text.toLocaleString()}

    },
    {
      title: 'Loft',
      dataIndex: 'loft',
      key: 'loft',
      width: '40%',
      editable: true,
      render: (text) => {
        if (text === null) {
          return ''
        }
        return text.toLocaleString()}

    },
    {
      title: 'Penthouse',
      dataIndex: 'penthouse',
      key: 'penthouse',
      width: '40%',
      editable: true,
      render: (text) => {
        if (text === null) {
          return ''
        }
        return text.toLocaleString()}

    },
    {
      title: 'Quarter',
      dataIndex: 'quarter',
      key: 'quarter',
      width: '25%',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      width: '25%',
    },
    {
      title: 'Researcher ID',
      dataIndex: 'researcher_id',
      key: 'researcher_id',
      width: '40%',
      editable: true,
    },
    {
      title: 'Updated?',
      dataIndex: 'is_updated',
      key: 'is_updated',
      width: '40%',
      render: (text) => {
        if (text.toString()=== 'true') {
          return "Yes"
        }
        return "No"}
    },
      {
        title: 'Actions',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey} = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <Mutation mutation={UPDATE_RESI_TABLE}>
                   {updateCurrentTable =>
                  (<Button
                    onClick={async() => {await this.save(form, record.id); updateCurrentTable({
                      variables: {
                        data_1br: this.state.latestEdit[0].data_1br,
                        data_2br: this.state.latestEdit[0].data_2br,
                        data_3br: this.state.latestEdit[0].data_3br,
                        data_4br: this.state.latestEdit[0].data_4br,
                        data_5br: this.state.latestEdit[0].data_5br,
                        loft: this.state.latestEdit[0].loft,
                        penthouse: this.state.latestEdit[0].penthouse,
                        studio: this.state.latestEdit[0].studio,
                        id: this.state.latestEdit[0].id,
                        researcher_id: this.state.latestEdit[0].researcher_id
                      }
                    })}}
                    style={{ marginRight: 8 }}
                  >
                    Save
                  </Button>)}
                  </Mutation>
                )}
              </EditableContext.Consumer>
              <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.id)}>
                <Button>Cancel</Button>
              </Popconfirm>
            </span>
          ) : (
            <Button disabled={editingKey !== ''} onClick={() => this.edit(record.id)}>
              Edit
            </Button>
          );
        },
      },
    ];
  }

  isEditing = record => record.id === this.state.editingKey;
changeHandler = name => value => {
  this.setState({
    [name]: value
  });
 };
fieldsAnew = (obj) => {
  return obj.map(fields => {
      fields.condominium = fields.condominium.name
  })
  
}

handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        const temp = this.state.latestEdit.concat(newData[index]);
        this.setState({data: newData, editingKey: '', latestEdit: temp});

      } else {
        const temp = this.state.latestEdit.concat(newData[index]);
        newData.push(row);
        this.setState({data: newData, editingKey: '', latestEdit: temp});
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key, latestEdit: [] });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };
    const { getFieldDecorator } = this.props.form;
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'researcher_id' ? 'text' : 'number',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });
    function hasErrors(fieldsError) {
      return Object.keys(fieldsError).some(field => fieldsError[field]);
    }
    const {
      getFieldsError,
      validateFields
    } = this.props.form;
    const handleSubmit = e => {
      e.preventDefault();
  
      validateFields(["variable", "year", "searchString", "quarter"], async (err, values) => {
        const {variable, year, searchString, quarter} = values
        if (!err) {
          // console.log(summary)
          // console.log(fields)
          
          await this.setState({searchString: searchString, year: year, variable: variable, quarter: quarter});
        }
      });
    };

    return (
      
      <EditableContext.Provider value={this.props.form}>
        <Card borderless style={{ padding: 24,
                    background: '#fff',
                    display: 'block',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap'}}>
         <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={handleSubmit}>
        <Form.Item label="Search">
          {getFieldDecorator('searchString', {
            initialValue: this.state.searchString
          })(<Input name="searchString" placeholder="Search for Condo name, City, or Developer" />)}
        </Form.Item>
        <Form.Item label="Year">
          {getFieldDecorator('year', {
            rules: [{ required: true, message: 'Please input the year!' }],
            initialValue: this.state.year
          })( <Select style={{ width: 120 }}>
          <Option value={2020}>2020</Option>
          <Option value={2019}>2019</Option>
          <Option value={2018}>2018</Option>
          <Option value={2017}>2017</Option>
          <Option value={2016}>2016</Option>
          <Option value={2015}>2015</Option>

        </Select>)}
        </Form.Item>
        <Form.Item label="Quarter">
          {getFieldDecorator('quarter', {
            rules: [{ required: true, message: 'Please input the Quarter!' }],
          })( <Select style={{ width: 100 }}>
          <Option value="Q1">Q1</Option>
          <Option value="Q2">Q2</Option>
          <Option value="Q3">Q3</Option>
          <Option value="Q4">Q4</Option>

        </Select>)}
        </Form.Item>
        <Form.Item label="Variable">
          {getFieldDecorator('variable', {
            initialValue: this.state.variable
          })( <Select style={{ width: 200 }}>
          <Option value="Remaining Inventory">Remaining Inventory</Option>
          <Option value="Average Unit Size">Average Unit Size</Option>
          <Option value="Sold Units">Sold Units</Option>
          <Option value="Sales Velocity">Sales Velocity</Option>
          <Option value="Minimum Package Price">Minimum Package Price</Option>
          <Option value="Maximum Size">Maximum Size</Option>
          <Option value="Maximum Price per SQM (in PHP)">Maximum Price per SQM (in PHP)</Option>
          <Option value="Average Price per SQM (in PHP)">Average Price per SQM (in PHP)</Option>
          <Option value="Number of Bedrooms">Number of Bedrooms</Option>
          <Option value="Average Package Price">Average Package Price</Option>
          <Option value="Maximum Package Price">Maximum Package Price</Option>
          <Option value="Total Units Per Type">Total Units Per Type</Option>
          <Option value="Minimum Size">Minimum Size</Option>
          <Option value="Minimum Price per SQM (in PHP)">Minimum Price per SQM (in PHP)</Option>
        </Select>)}
        </Form.Item>
        <Form.Item>
        <Button
        type="primary"
        htmlType="submit"
        disabled={hasErrors(getFieldsError())}
        style={{float: 'right', margin: '0 auto'}}
      >
        Submit
      </Button>
      </Form.Item>
        </Form>
        {this.state.searchString==='' ? (
          <Query query={ALL_MELTEDMPS} onCompleted={async data => {await this.fieldsAnew(data.developments_schema_melted_mp); this.setState({data: data.developments_schema_melted_mp})}}>
          {({ data, loading, error}) => {
          if (loading) return <div style={{textAlign: 'center'}}>
                  <Icon type="loading" style={{'fontSize': '50px'}} />
                  </div>;
          if (error) return `${error}`;
          return (
                      <Table
    components={components}
    dataSource={this.state.data}
    columns={columns}
    rowClassName="editable-row"
    rowKey="id"
    pagination={{
      onChange: this.cancel,
      defaultPageSize: 13, showSizeChanger: true, pageSizeOptions: ['13', '26', '39', '52']
    }}
  />
          );
          }} 
          </Query>
         ): (this.state.variable === '' ? <>
          <Query query={MPFILTERNOVAR} variables={{'searchString1': `%${this.state.searchString}`, 'searchString2': `%${this.state.searchString}%`, 'searchString3': `${this.state.searchString}%`,'year': parseInt(this.state.year), 'quarter': this.state.quarter}} onCompleted={async data => {await this.fieldsAnew(data.developments_schema_melted_mp); this.setState({data: data.developments_schema_melted_mp})}}>
                  {({ data, loading, error}) => {
                  if (loading) return <div style={{textAlign: 'center'}}>
                          <Icon type="loading" style={{'fontSize': '50px'}} />
                          </div>;
                  if (error) return `${error}`;
                  return (
                              <Table
            components={components}
            dataSource={this.state.data}
            columns={columns}
            rowClassName="editable-row"
            rowKey="id"
            pagination={{
              onChange: this.cancel,
              defaultPageSize: 13, showSizeChanger: true, pageSizeOptions: ['13', '26', '39', '52']
            }}
          />
                  );
                  }} 
                  </Query>
           </> : <>
          <Query query={MPFILTER} variables={{'searchString1': `%${this.state.searchString}`, 'searchString2': `%${this.state.searchString}%`, 'searchString3': `${this.state.searchString}%`,'year': parseInt(this.state.year), 'variable': this.state.variable, 'quarter': this.state.quarter}} onCompleted={async data => {await this.fieldsAnew(data.developments_schema_melted_mp); this.setState({data: data.developments_schema_melted_mp})}}>
                  {({ data, loading, error}) => {
                  if (loading) return <div style={{textAlign: 'center'}}>
                          <Icon type="loading" style={{'fontSize': '50px'}} />
                          </div>;
                  if (error) return `${error}`;
                  return (
                              <Table
            components={components}
            dataSource={this.state.data}
            columns={columns}
            rowClassName="editable-row"
            rowKey="id"
            pagination={{
              onChange: this.cancel,
              defaultPageSize: 13, showSizeChanger: true, pageSizeOptions: ['13', '26', '39', '52']
            }}
          />
                  );
                  }} 
                  </Query>
           </>)}
          <Button icon="download" type="link" >
          <CSVLink data={this.state.data} filename={`generated-by-reaper-${new Date().getTime()}.csv`}> Export to CSV</CSVLink>
         </Button>
         </Card>
      </EditableContext.Provider>
    );
  }
}



const EditableFormTable = Form.create()(UpdateTable);
export default EditableFormTable;