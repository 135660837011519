import React from 'react';
import {Link} from 'react-router-dom'
import { Card, Tabs, Icon, Button, Table} from 'antd';
import {useQuery } from '@apollo/react-hooks';
import ALLCONDOS from '../api/queries/allCondos';
import TableProfile from './CondoProfile'
import TablePayment from './CondoPayment';
import TableMisc from './CondoMisc';
const { TabPane } = Tabs;


function NewCondoTable() {
  const { loading, error, data } = useQuery(ALLCONDOS);
  
  if (loading) return <div style={{textAlign: 'center'}}>
  <Card><Icon type="loading" style={{fontSize: '50px'}}/></Card>
  </div>;
  if (error) return `Error! ${error.message}`;
return (
    <>
    <Button href="/home/add" type="primary" shape="round" icon="plus">
      Add New Condominium
      </Button>
    <Card borderless style={{
        margin: 10,
        background: '#fff'}}>
    <Tabs defaultActiveKey="1" style={{overflowX: 'auto'}}>
        <TabPane
      tab={
        <span>
          <Icon type="read" />
          Condo Profile
        </span>
      }
      key="1"
    >
      <TableProfile dataSource={data.developments_schema_condominium}/>
    </TabPane>
    <TabPane
    tab={
      <span>
       <Icon type="dollar" />
        Payment Options
      </span>
    }
    key="2"
  >
    <TablePayment dataSource={data.developments_schema_condominium}/>
  </TabPane>
  <TabPane
    tab={
      <span>
       <Icon type="more" />
        Miscellaneous
      </span>
    }
    key="3"
  >
    <TableMisc dataSource={data.developments_schema_condominium}/>
  </TabPane>
    </Tabs>
    </Card>
    </>
)
}

export default NewCondoTable;