import React, { useState } from 'react';
import { Form, Input, Button, Row, Card} from 'antd';
import {Auth} from 'aws-amplify';
import { Notices } from "../components/Notices";
import WrappedForgotPasswordVerificationForm from './ForgotPasswordVerification';


const ForgotPassword = (props) => {
    const [errors, setErrors] = useState({errors: {
        message: ""
    }});
    const [forgotPassword, setforgotPassword] = useState(false);
    const [user, setUser] = useState("");
    const switchToVerify = () => {
      var isforgotPassword = true;
      setforgotPassword(isforgotPassword);
    }
    function switchToGoBack () {
      var isforgotPassword = false;
      setforgotPassword(isforgotPassword);
    }
    const redirectBack = () => {
      props.history.push("/login"); 
    }
    const { getFieldDecorator } = props.form;
      const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
          const {username} = values;
          if (!err) {
                  await Auth.forgotPassword(username).then(setUser(username)).catch(cognitoerror => {
                  let cognitoerr = null;
                  !cognitoerror.message ? cognitoerr = {"message": cognitoerror} : cognitoerr = cognitoerror;
                  setErrors({...cognitoerr});
                }
                  );
                  switchToVerify();
          }
          else {
            let cognitoerr = null;
            !err.message ? cognitoerr = {"message": err} : cognitoerr = err;
            setErrors({...cognitoerr});
          }
        }
        )};
    return (
        <>
        <Notices errors={errors}/>
        {!forgotPassword ? (<Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}} >
            <Card title="Forgot REAPER Password" style={{width: '375px', height: 'auto'}}>
            <Form onSubmit={handleSubmit}>
            <Form.Item label="Username" extra="Note: Type your employee ID in all lowercase letters.">
        {getFieldDecorator('username', {
          rules: [{ required: true, message: 'Please input your username!' }],
        })(
          <Input
          />,
        )}
      </Form.Item>
    
            <Button 
            type="primary" 
            htmlType="submit" 
            style={{width: '100%'}}
            >
            Submit
            </Button>
            
            </Form>
            <Button 
        type="link" 
        href="/login"
        style={{margin: '10 px', width: '100%'}}
        >
        Cancel
        </Button>
            </Card>

        </Row>): (<WrappedForgotPasswordVerificationForm username={user} history={redirectBack} back={switchToGoBack}/>)}
        </>
    );
};

const WrappedForgotPasswordForm = Form.create({ name: 'forgot_password' })(ForgotPassword);


export default WrappedForgotPasswordForm;