import React from 'react';
import {Button, Table} from 'antd';


const expandedRowRenderProfile = (record, i) => {
    const profile_expanded = [{
      title: 'Coordinates',
      dataIndex: 'geo_tagging',
      key: 'geo_tagging',
  
  },
  {
    title: 'Province',
    dataIndex: 'province',
    key: 'province',
  },
  {
      title: 'Street Address',
      dataIndex: 'street_and_barangay',
      key: 'street_and_barangay',
  },
  {
      title: 'No. of Floors',
      dataIndex: 'number_of_floors',
      key: 'number_of_floors',
      width: '15%'
  },
  {
      title: 'Lot Area (in SQM)',
      dataIndex: 'lot_area_sqm',
      key: 'lot_area_sqm',
      width: '15%'
  
  },
  {
      title: 'Turnover Year',
      dataIndex: 'turnover_year',
      key: 'turnover_year',
      width: '15%'
  
  },
  {
      title: 'Age (in Years)',
      dataIndex: 'age_yrs',
      key: 'age_yrs',
      width: '15%'
  
  },
  {
      title: 'Age (in Months)',
      dataIndex: 'age_mos',
      key: 'age_mos',
      width: '15%'
  
  },
  {
    title: 'Action',
    dataIndex: 'operation',
    key: 'operation',
    render: () => (
      <span className="table-operation">
        <Button type="secondary" shape="round">Edit</Button>
      </span>
    ),
  },
    ]

    return <Table columns={profile_expanded} dataSource={[record]} pagination={false} rowKey={record.id}/>
  }

  function TableProfile (props) {
    const profile_main = [
      {
          title: 'Condominium',
          dataIndex: 'name',
          key: 'name',
      },
      {
          title: 'Building No.',
          dataIndex: 'bldgs',
          key: 'bldgs',
      },
      {
          title: 'Researcher',
          dataIndex: 'researcher_analyst',
          key: 'researcher_analyst',
      },
      {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
      },
      {
          title: 'Date of Research',
          dataIndex: 'date_of_research',
          key: 'date_of_research',
  
      },
      {
          title: 'Developer',
          dataIndex: 'developer',
          key: 'developer',
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: 'CBD',
        dataIndex: 'cbd_location',
        key: 'cbd_location',
      },
  ]
 return <Table className="components-table-demo-nested" dataSource={props.dataSource} columns={profile_main} expandedRowRender={expandedRowRenderProfile} rowKey={(record) => record.id} /> 
  }

  export default TableProfile;