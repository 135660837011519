import React from 'react';
import {Row, Col, Card} from 'antd';
const CardContainer = ({ children }) => (
  <Row>
    <Col span={12} offset={6}>
      <Card hoverable={true} bordered={false} style={styles.container}>
        { children }
      </Card>
    </Col>
  </Row>
)

const styles = {
  container: {
    margin: '0 auto',
    verticalAlign: 'center'
  }
}

export default CardContainer