import gql from 'graphql-tag';


const USER_HISTORY = gql`query userHistory {
    logging_melted_mp_audit(order_by: {date_of_action: desc}) {
      date_of_action
      operation
      name
      user_name
      variable
      diff
    }
  }
  `

export default USER_HISTORY;