import React from 'react';
import {Button, Table} from 'antd';

const expandedRowRenderPayment = (record, i) => {
    const payment_expanded = [{
      title: 'BF DP %',
      dataIndex: 'bank_financing_dp_percent',
      key: 'bank_financing_dp_percent',
  
  },
    {
      title: 'BF DP Amortization (in Months)',
      dataIndex: 'bank_financing_dp_amort_mos',
      key: 'bank_financing_dp_amort_mos',
  
  },
  {
    title: 'BF Balance %',
    dataIndex: 'bank_financing_bal_percent',
    key: 'bank_financing_bal_percent',

  },
    {
      title: 'BF Balance Amortization (in Months)',
      dataIndex: 'bank_financing_bal_amort_mos',
      key: 'bank_financing_bal_amort_mos',
  },
  {
    title: 'BF DP Interest Rate %',
    dataIndex: 'bank_financing_interest_rate_percent',
    key: 'bank_financing_interest_rate_percent',


  },
  {
    title: 'IHF DP %',
    dataIndex: 'in_house_financing_dp_percent',
    key: 'in_house_financing_dp_percent',

},
  {
    title: 'IHF DP Amortization (in Months)',
    dataIndex: 'ihf_dp_amort_mos',
    key: 'ihf_dp_amort_mos',

},
{
  title: 'IHF Balance %',
  dataIndex: 'ihf_balance_percent',
  key: 'ihf_balance_percent',
},
  {
    title: 'IHF Balance Amortization (in Months)',
    dataIndex: 'ihf_bal_amort_mos',
    key: 'ihf_bal_amort_mos',


},
{
  title: 'IHF DP Interest Rate %',
  dataIndex: 'ihf_interest_rate',
  key: 'ihf_interest_rate',

},
  {
    title: 'Action',
    dataIndex: 'operation',
    key: 'operation',
    render: () => (
      <span className="table-operation">
        <Button type="secondary" shape="round">Edit</Button>
      </span>
    ),
  },
    ]

    return <Table columns={payment_expanded} dataSource={[record]} pagination={false} rowKey={record.id}/>
  }

  function TablePayment (props) {
    const payment_main = [
      {
          title: 'Condominium',
          dataIndex: 'name',
          key: 'name',
      },
      {
          title: 'Building No.',
          dataIndex: 'bldgs',
          key: 'bldgs',
      },
      {
          title: 'Payment Discount %',
          dataIndex: 'cash_payment_disc_percent',
          key: 'cash_payment_disc_percent',
      },
      {
          title: 'Deferred Payment (in Months)',
          dataIndex: 'deferred_payment_mos',
          key: 'deferred_payment_mos',
      },
      {
          title: 'Pag-Ibig DP',
          dataIndex: 'pag_ibig_dp',
          key: 'pag_ibig_dp',
      },
      {
        title: 'Pag-Ibig Amortization',
        dataIndex: 'pag_ibig_amort',
        key: 'pag_ibig_amort',

    },
      {
        title: 'Reservation Fee',
        dataIndex: 'reservation_fee',
        key: 'reservation_fee',
      },
      {
        title: 'Full Notes',
        dataIndex: 'full_notes',
        key: 'full_notes',
      },
      {
        title: 'Selling Point Concept',
        dataIndex: 'selling_point_concept',
        key: 'selling_point_concept',
      },
      {
        title: "Buyers' Profile",
        dataIndex: 'buyers_profile',
        key: 'buyers_profile',
      },
  ]
 return <Table className="components-table-demo-nested" dataSource={props.dataSource} columns={payment_main} expandedRowRender={expandedRowRenderPayment} rowKey={(record) => record.id} /> 
  }

  export default TablePayment;